import React from "react"
import MainLayout from "../layouts"
import { Col, Container, Row } from "react-bootstrap"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import ContactHeading from "../components/ContactHeading"
import ListWithDescription from "../components/ListWithDescription"

export const pageQuery = graphql`
  query {
    residentialBackgroundImage: file(
      relativePath: { eq: "residential-background.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    locksmithWorkingOnDoorLockImage: file(
      relativePath: { eq: "locksmith-working-on-door-lock.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    locksmithPickingDoorLockImage: file(
      relativePath: { eq: "locksmith-picking-door-lock.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    laserKeyMachineImage: file(relativePath: { eq: "laser-key-machine.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const servicesData = [
  {
    heading: "24/7 Emergency Lockout",
    description:
      "If you are locked out of your house, lost your keys to your home, need a replacement key at an odd hour, you can always count on the services of Swift Locksmith. Our technicians work around the clock and are available during the holidays.",
  },
  {
    heading: "Whole House Re-key",
    description:
      "Moved in to a new home and need to change your locks? Swift Locksmith services can offer you a variety of residential locks, standard locks and high security locks and rekey the entire house in just one visit.",
  },
  {
    heading: "Lock Repair & Maintenance",
    description:
      "We come to you for all of your repair and maintenance needs, including house, garage, and room locks. Our locksmith van is fully equipped with a key cutting machine and all the tools necessary to get the job done at your location.",
  },
  {
    heading: "Pick Proof Cylinder Deadbolts",
    description:
      "The safety and privacy of your home are important that's why we offer premium quality deadbolts for an added level of security. Whether you are looking to get new deadbolts installed or want to repair your existing ones, our skilled technicians can help you determine if you will need repairs or a replacement.",
  },
  {
    heading: "On-site Key Extraction",
    description:
      "It’s common to break a key into a lock or have a lock jam, especially when you’re in a rush and that's why our technicians are trained to quickly and carefully extract all broken pieces from a lock without damaging it.",
  },
  {
    heading: "Master Key Systems",
    description:
      "Our technicians are trained and experienced in master key systems installation and maintenance. Whether you need a master key to secure a room in your home or safes to be protected, Swift Locksmith has you covered.",
  },
]

function ResidentialPage({ data }) {
  return (
    <MainLayout
      title="24 Hour Residential Locksmith Near Me | 916-995-0135"
      description="Our skilled techs are well equipped to repair various residential door locks, rekey, professionally lock pick & more. Free Estimates. Competitive Pricing."
      offset
    >
      <ContactHeading
        background={data.residentialBackgroundImage.childImageSharp.fluid}
      >
        <h1>Residential Locksmith Services</h1>
      </ContactHeading>
      <Container as="section">
        <h2 className="text-center">Residential Locksmith Solutions</h2>
        <p className="indent">
          How safe do you feel when you go to sleep at night? A home cannot feel
          safe and secure when your locks and security are not as safe as they
          could be. We are concerned with the safety and security of your home
          and want you to be comfortable and secure. We provide a large range of
          residential locksmith solutions. Check out our{" "}
          <a
            href="https://swiftlocksmith-locksmith.business.site/posts/1902784534765012291?hl=en"
            target="_blank"
          >
            blog
          </a>{" "}
          on a safe home and some security tips to learn more about providing a
          secure environment for your home.
        </p>
        <p className="indent mb-0">
          You should always feel safe and secure in your own home. When your
          sense of security is comprised, you can always rely on us to take care
          of your needs to help you feel safe again. We can repair, replace,
          install or even as simple as consult you with locks, safes, doors,
          gates and offer many more services to your home security needs.
        </p>
      </Container>
      <section>
        <ListWithDescription
          items={servicesData}
          heading="Residential Services"
        />
      </section>
      <Container as="section" className="d-none d-sm-block">
        <Row noGutters>
          <Col xs={4}>
            <Img
              fluid={data.locksmithWorkingOnDoorLockImage.childImageSharp.fluid}
              alt="locksmith-working-on-door-lock"
              style={{ maxWidth: "100%" }}
            />
          </Col>
          <Col xs={4}>
            <Img
              fluid={data.locksmithPickingDoorLockImage.childImageSharp.fluid}
              alt="locksmith-picking-door-lock"
              style={{ maxWidth: "100%" }}
            />
          </Col>
          <Col xs={4}>
            <Img
              fluid={data.laserKeyMachineImage.childImageSharp.fluid}
              alt="laser-key-machine"
              style={{ maxWidth: "100%" }}
            />
          </Col>
        </Row>
      </Container>
      <Container as="section" className="mb-5">
        <h2>Home Security Solutions</h2>
        <p className="indent">
          Security in the home is very important and preventative maintenance is
          the best way to keep a bad situation from ever happening. As a
          homeowner it is important to keep your family and valuables safe, but
          it requires the help of a professional. Swift Locksmiths can help you
          see areas that are often overlooked and perhaps allow an entrance for
          a clever burglar or thief. We also provide high-end security devices
          installed in your home if that is your goal.
        </p>
        <p className="indent">
          Our reputation is for both quality work and customer satisfaction, and
          you can depend on that being the case, every time. Swift Locksmiths is
          composed of fully licensed locksmiths and security technicians who use
          the most advanced and high-quality locksmith products to provide
          businesses and corporations with top-notch security and unshakable
          peace of mind. Every team member at Swift Locksmiths is not only
          skilled but committed to the customer’s safety, security, and
          satisfaction.
        </p>
        <p className="indent mb-0">
          We can provide a layered approach with cameras and magnetic cards if
          you have sensitive information at home that you must keep safe. We
          also can install gates on your property or service your existing
          security features. So please let us handle all of your residential
          locksmith and security needs. Do not wait too long to secure your
          assets and keep your loved ones safe. We look forward to talking to
          you soon.
        </p>
      </Container>
    </MainLayout>
  )
}

export default ResidentialPage
